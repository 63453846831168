export const buyboxATVPlusPromoPrices = {
	US: { promotionPrice: '$2.99', normalPrice: '$9.99' },
	GB: { promotionPrice: '£2.99', normalPrice: '£8.99' },
	FR: { promotionPrice: '€4,99', normalPrice: '€9,99' },
	DE: { promotionPrice: '€4,99', normalPrice: '€9,99' },
	CA: { promotionPrice: 'CA$3.99', normalPrice: 'CA$12.99' },
	AU: { promotionPrice: 'A$3.99', normalPrice: 'A$12.99' },
	ES: { promotionPrice: '€4,99', normalPrice: '€9,99' },
	CH: { promotionPrice: '€4,99', normalPrice: '€9,99' },
	IT: { promotionPrice: '€4,99', normalPrice: '€9,99' },
	MX: { promotionPrice: '$69.00', normalPrice: '$129.00' },
	FI: { promotionPrice: '€4,99', normalPrice: '€9,99' },
	BE: { promotionPrice: '€4,99', normalPrice: '€9,99' },
	PH: { promotionPrice: '$1.99', normalPrice: '$6.99' },
	NL: { promotionPrice: '€4,99', normalPrice: '€9,99' },
	BR: { promotionPrice: 'R$9.90', normalPrice: 'R$21.90' },
	PT: { promotionPrice: '€4,99', normalPrice: '€9,99' },
	IE: { promotionPrice: '€4,99', normalPrice: '€9,99' },
	DK: { promotionPrice: '€4,99', normalPrice: '€9,99' },
	NO: { promotionPrice: '€4,99', normalPrice: '€9,99' },
	ID: { promotionPrice: '$1.99', normalPrice: '$6.99' },
	CZ: { promotionPrice: '€4,99', normalPrice: '€9,99' },
	SE: { promotionPrice: '€4,99', normalPrice: '€9,99' },
	AE: { promotionPrice: '$2.99', normalPrice: '$7.99' },
	CO: { promotionPrice: '$1.99', normalPrice: '$6.99' },
	GR: { promotionPrice: '€4,99', normalPrice: '€9,99' },
	IL: { promotionPrice: '$1.99', normalPrice: '$6.99' },
	SA: { promotionPrice: '$2.99', normalPrice: '$7.99' },
	PL: { promotionPrice: '9,99 zł', normalPrice: '34,99 zł' },
	CL: { promotionPrice: '$1.99', normalPrice: '$6.99' },
	AR: { promotionPrice: '$1.99', normalPrice: '$6.99' },
	HU: { promotionPrice: '€4,99', normalPrice: '€9,99' },
	SG: { promotionPrice: '$2.99', normalPrice: '$7.99' },
	ZA: { promotionPrice: '$1.99', normalPrice: '$6.99' },
	IN: { promotionPrice: '$1.99', normalPrice: '$6.99' },
	AT: { promotionPrice: '€4,99', normalPrice: '€9,99' },
	TH: { promotionPrice: '$1.99', normalPrice: '$6.99' },
	NZ: { promotionPrice: 'NZ$3.99', normalPrice: 'NZ$14.99' },
	MY: { promotionPrice: '$1.99', normalPrice: '$6.99' },
	PE: { promotionPrice: '$1.99', normalPrice: '$6.99' },
	LT: { promotionPrice: '€4,99', normalPrice: '€9,99' },
	UA: { promotionPrice: '$0.99', normalPrice: '$4.99' },
	DO: { promotionPrice: '$1.99', normalPrice: '$6.99' },
	RU: { promotionPrice: '$1.99', normalPrice: '$6.99' },
	BG: { promotionPrice: '€4,99', normalPrice: '€9,99' },
	JP: { promotionPrice: '¥200.00', normalPrice: '¥900.00' },
	SK: { promotionPrice: '€4,99', normalPrice: '€9,99' },
	CR: { promotionPrice: '$1.99', normalPrice: '$6.99' },
	EC: { promotionPrice: '$1.99', normalPrice: '$6.99' },
	EG: { promotionPrice: '$0.99', normalPrice: '$2.99' },
	LV: { promotionPrice: '€4,99', normalPrice: '€9,99' },
	LU: { promotionPrice: '€4,99', normalPrice: '€9,99' },
	GT: { promotionPrice: '$1.99', normalPrice: '$6.99' },
	HK: { promotionPrice: '$2.99', normalPrice: '$7.99' },
	SI: { promotionPrice: '€4,99', normalPrice: '€9,99' },
	EE: { promotionPrice: '€4,99', normalPrice: '€9,99' },
	QA: { promotionPrice: '$1.99', normalPrice: '$6.99' },
	LK: { promotionPrice: '$1.99', normalPrice: '$6.99' },
	PA: { promotionPrice: '$1.99', normalPrice: '$6.99' },
	PY: { promotionPrice: '$1.99', normalPrice: '$6.99' },
	CY: { promotionPrice: '€4,99', normalPrice: '€9,99' },
	TW: { promotionPrice: '$1.99', normalPrice: '$7.99' },
	MN: { promotionPrice: '$1.99', normalPrice: '$6.99' },
	HN: { promotionPrice: '$1.99', normalPrice: '$6.99' },
	MT: { promotionPrice: '€4,99', normalPrice: '€9,99' },
	BO: { promotionPrice: '$1.99', normalPrice: '$6.99' },
	MU: { promotionPrice: '$1.99', normalPrice: '$6.99' },
	JO: { promotionPrice: '$1.99', normalPrice: '$6.99' },
	GH: { promotionPrice: '$1.99', normalPrice: '$7.99' },
	KH: { promotionPrice: '$1.99', normalPrice: '$7.99' },
	MD: { promotionPrice: '€4,99', normalPrice: '€9,99' },
	AZ: { promotionPrice: '$1.99', normalPrice: '$6.99' },
	TT: { promotionPrice: '$1.99', normalPrice: '$6.99' },
	BH: { promotionPrice: '$1.99', normalPrice: '$7.99' },
	BS: { promotionPrice: '$1.99', normalPrice: '$7.99' },
	AM: { promotionPrice: '$1.99', normalPrice: '$7.99' },
	SV: { promotionPrice: '$1.99', normalPrice: '$6.99' },
	NI: { promotionPrice: '$1.99', normalPrice: '$6.99' },
	OM: { promotionPrice: '$1.99', normalPrice: '$6.99' },
	UG: { promotionPrice: '$1.99', normalPrice: '$7.99' },
	FJ: { promotionPrice: '$1.99', normalPrice: '$6.99' },
	LB: { promotionPrice: '$1.99', normalPrice: '$6.99' },
	BY: { promotionPrice: '$1.99', normalPrice: '$7.99' },
	ZW: { promotionPrice: '$1.99', normalPrice: '$7.99' },
	BW: { promotionPrice: '$1.99', normalPrice: '$6.99' },
	MZ: { promotionPrice: '$1.99', normalPrice: '$6.99' },
	KY: { promotionPrice: '$1.99', normalPrice: '$6.99' },
	VE: { promotionPrice: '$1.99', normalPrice: '$6.99' },
	BM: { promotionPrice: '$1.99', normalPrice: '$6.99' },
	GD: { promotionPrice: '$1.99', normalPrice: '$6.99' },
	GW: { promotionPrice: '$1.99', normalPrice: '$6.99' },
	AG: { promotionPrice: '$1.99', normalPrice: '$6.99' },
	KN: { promotionPrice: '$1.99', normalPrice: '$6.99' },
	CV: { promotionPrice: '$1.99', normalPrice: '$7.99' },
	SZ: { promotionPrice: '$1.99', normalPrice: '$6.99' },
	MO: { promotionPrice: '$2.99', normalPrice: '$9.99' },
	AI: { promotionPrice: '$1.99', normalPrice: '$6.99' },
	TJ: { promotionPrice: '$1.99', normalPrice: '$7.99' },
	BZ: { promotionPrice: '$1.99', normalPrice: '$6.99' },
	LA: { promotionPrice: '$1.99', normalPrice: '$7.99' },
	HR: { promotionPrice: '€4,99', normalPrice: '€9,99' },
};
